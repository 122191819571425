(function () {
    'use strict';
    $(document).ready(function () {
        window.animationsCollections = [];

        function init_domek() {
            const wrapper = $("#domek-wrapper");
            if (wrapper.length) play();

            function hide() {
                wrapper.addClass('hide');
                wrapper.removeClass('play');
                setTimeout(function () {
                    play();
                }, 500);
            }

            function play() {
                wrapper.removeClass('hide');
                wrapper.addClass('play');
                setTimeout(function () {
                    hide();
                }, 3200);
            }
        }

        function init_monety() {
            const wrapper = $("#monety-wrapper");
            if (wrapper.length) play();

            function hide() {
                wrapper.addClass('hide');
                wrapper.removeClass('play');
                setTimeout(function () {
                    play();
                }, 500);
            }

            function play() {
                wrapper.removeClass('hide');
                wrapper.addClass('play');
                setTimeout(function () {
                    hide();
                }, 1500);
            }
        }

        function init_wykres_liniowy() {
            const wrapper = $("#wykres_liniowy-wrapper");
            if (wrapper.length) play();

            function hide() {
                wrapper.addClass('hide');
                wrapper.removeClass('play');
                setTimeout(function () {
                    play();
                }, 500);
            }

            function play() {
                wrapper.removeClass('hide');
                wrapper.addClass('play');
                setTimeout(function () {
                    hide();
                }, 4000);
            }
        }

        function init_wykres_slupkowy() {
            const wrapper = $("#wykres_slupkowy-wrapper");
            if (wrapper.length) play();

            function hide() {
                wrapper.addClass('hide');
                wrapper.removeClass('play');
                setTimeout(function () {
                    play();
                }, 500);
            }

            function play() {
                wrapper.removeClass('hide');
                wrapper.addClass('play');
                setTimeout(function () {
                    hide();
                }, 3200);
            }
        }

        function init_wieza() {
            const wrapper = $("#wieza-wrapper");
            if (wrapper.length) play();

            function hide() {
                wrapper.addClass('hide');
                wrapper.removeClass('play');
                setTimeout(function () {
                    play();
                }, 500);
            }

            function play() {
                wrapper.removeClass('hide');
                wrapper.addClass('play');
                setTimeout(function () {
                    hide();
                }, 3000);
            }
        }

        if ($('.col-big-numbers .icon-svg').length) {
            window.animationsCollections = [
                init_domek,
                init_monety,
                init_wykres_liniowy,
                init_wykres_slupkowy,
                init_wieza
            ];
            window.animationsCollections.forEach((anim) => anim());
        }

    });
}(jQuery));
