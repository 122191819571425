(function () {
    'use strict';
    $(document).ready(function () {

        //Form logic
        function sendNewsletterPostNotificationForm(formId) {

            var email = $(formId).find('input[id^=npn-email-]').val();

            var consents = [];
            $(formId).find('.field-checkbox-consent').each(function () {
                var consentContent = $(this).find('label').text();
                var consentAnswer = $(this).find('input[type=checkbox]:checked').val() ? 'TAK' : 'NIE';
                var consentAnswer = {
                    "agreements": {
                        "consentContent": consentContent,
                        "consentAnswer": consentAnswer,
                    }
                }
                consents.push(consentAnswer);
            });

            var newsletterPostNotificationFormDetails = {
                "newsletterEmail": email,
                "newsletterConsents": consents,
            }

            var newsletterPostNotificationFormDetailsJSON = JSON.stringify(newsletterPostNotificationFormDetails);

            grecaptcha.ready(function () {
                grecaptcha.execute(window.fh_config.captcha_site_key, {action: 'submit'}).then(function (token) {
                    $.ajax({
                        url: "/wp-admin/admin-ajax.php",
                        method: "post",
                        timeout: 10000,
                        data: {
                            action: "send_post_notification_form_sign_up",
                            newsletterPostNotificationFormDetailsJSON: newsletterPostNotificationFormDetailsJSON,
                            nonce : window.fh_config.nonce,
                            recaptcha_token: token,
                        },
                        success: function (data) {
                            $('.ajax-info').text(data);
                            $('.ajax-info').slideDown();
                            $(formId).trigger("reset");
                        },
                        error: function (data) {
                            $('.ajax-error').slideDown();
                        },
                        beforeSend: function () {
                            $('.ajax-info').slideUp();
                            $('.ajax-loading').slideDown();
                            $('.ajax-error').slideUp();
                        },
                        complete: function () {
                            $('.ajax-loading').slideUp();
                        },
                    });
                })
            });
        }

        function validateEmail(email) {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

        $('form[id^=npn-form-]').on('submit', function (e) {
            e.preventDefault();
            $('.cf-error').hide();
            var currFormId = '#' + $(this).attr('id');

            var isAllValid = true;

            if (!validateEmail($(currFormId).find('input[id^=npn-email-]').val())) {
                $(currFormId).find('input[id^=npn-email-]').siblings('.cf-error').slideDown();
                isAllValid = false;
            }

            $(currFormId).find('.field-checkbox-consent-required').each(function() {
                if (!$(this).find('input[type=checkbox]').is(':checked')) {
                    $(this).find('input[type=checkbox]').siblings('.cf-error').slideDown();
                    isAllValid = false;
                }
            });

            if (!isAllValid) {
                return;
            }

            sendNewsletterPostNotificationForm(currFormId);
        });

    });
}(jQuery));
