(function () {
    'use strict';
    $(document).ready(function () {
        console.log('subpage.js');

        function setProgressBar() {
            var scrollTop = $(window).scrollTop();
            var docHeight = $(document).height();
            var winHeight = $(window).height();
            var scrollPercent = (scrollTop) / (docHeight - winHeight);
            $('.progress-bar-indicator').css({
                '-webkit-transform': 'scaleX(' + scrollPercent + ')',
                '-moz-transform': 'scaleX(' + scrollPercent + ')',
                '-ms-transform': 'scaleX(' + scrollPercent + ')',
                '-o-transform': 'scaleX(' + scrollPercent + ')',
                'transform': 'scaleX(' + scrollPercent + ')'
            });
        }

        $(window).scroll(function () {
            setProgressBar();
        });

        if (window.matchMedia("(min-width: 1366px)").matches) {

            //Prograss bar header trigger
            if ($(this).scrollTop() > 0) {
                $('.header-sub').addClass('scrolled');
            }

            var lastY = $(window).scrollTop();

            $(window).on('scroll', function () {
                var top = $(this).scrollTop();
                if (top > 400) {
                    if (top > lastY) {
                        $('.header-sub').addClass('scrolled');
                    } else {
                        $('.header-sub').removeClass('scrolled');
                    }
                } else {
                    $('.header-sub').removeClass('scrolled');
                }

                lastY = top;
            });

        }

        $('.management .person-bio-more').on('click', function(e) {
            e.preventDefault();
            $(this).toggleClass('open');
            $(this).siblings('.person-bio').slideToggle();
        });

        $('.directors-board .person-bio-more').on('click', function(e) {
            e.preventDefault();
            $(this).toggleClass('open');
            $(this).parent().find('.person-bio').slideToggle();
        });

        //Calendar
        $('.save-in-outlook').click(function(e){

            var date = moment($(this).data('date'),'DD/MM/YYYY');
            var cal = ics();
            var beginDate = date.format('YYYY/MM/DD');
            var endDate = date.add(1, 'days').format('YYYY/MM/DD');
            cal.addEvent(
                $(this).data('name'),
                $(this).data('description'),
                'Warszawa',
                beginDate,
                endDate
            );
            cal.download("calevent");

            e.preventDefault();
            return false;
        });

        // SOCIAL SHARING
        var $share = $('#share');
        $share.jsSocials({
            showLabel: false,
            showCount: false,
            shareIn: "popup",
            text: document.title,
            url: window.location.href,
            shares: [
                {
                    share: "facebook",
                    logo: `${$share.attr('data-templateURL')}/dist/images/facebook.svg`,
                    inlineSvg: true,
                },
                {
                    share: "twitter",
                    logo: `${$share.attr('data-templateURL')}/dist/images/twitter.svg`,
                    inlineSvg: true,
                    via: "pgnig",
                },
                {
                    share: "linkedin",
                    logo: `${$share.attr('data-templateURL')}/dist/images/linkedin.svg`,
                    inlineSvg: true,
                }
            ]
        });


        //tools show/hide
        $('#tools-toggle').click(function () {
            $('.tools-list').animate({
                height: 'toggle'
            });
            $(this).toggleClass('opened');
        });

        // accordion scroll animation
        $('.subpage .accordion.scroll-animation .collapse, .subpage .accordion .collapse').on('shown.bs.collapse', function (e) {
            updateTableSize();
            var positionTop = $(this).parent().offset().top; // - $('header').height();
            $('html, body').animate({
                scrollTop: positionTop
            }, 500);
            $(window).resize();
        });

        // responsive image enlarging
        $('.popup-trigger').click(function (e) {
            $(this).parent().find('.popup-image-container').fadeIn();
            e.preventDefault();
            return false;
        });

        $('.popup-image-container').click(function () {
            $(this).fadeOut();
        });

        //Tooltipster

        // default tooltips
        $('.default-tooltipster').tooltipster({
            maxWidth: 290,
            contentAsHTML: true,
            interactive: true,
            functionPosition: function (instance, helper, position) {
                position.coord.left = helper.geo.origin.offset.left - 20;
                return position;
            },
        });

        $('.subpage .swiper-qdt').each(function (index) {
            var qdtSwiper = new Swiper($(this)[0], {
                slidesPerView: 3,
                spaceBetween: 0,
                navigation: {
                    prevEl: '.swiper-button-prev',
                    nextEl: '.swiper-button-next',
                },
                breakpoints: {
                    767: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                    },
                    575: {
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                    }
                }
            });
        });

        var $sidebar = $('.col-tabs-nav-sticky');
        if (window.matchMedia("(min-width: 992px)").matches && $sidebar.length) {
            var sidebarTop = $sidebar.offset().top;
            var rightContent = $('.col-tabs');
            var rightContentBottom = rightContent.outerHeight() + rightContent.offset().top;
            var topBarHeight = $('header').outerHeight();
            var sidebarRemainingSpace = $(window).height() - $sidebar.outerHeight() - topBarHeight - 30;

            $(window).scroll(fixSidebarOnScroll);

            function fixSidebarOnScroll() {

                //Window got resized after listener was attached
                if (window.matchMedia("(max-width: 991px)").matches) {
                    $sidebar.removeClass('stick').removeClass('stick-bottom');
                    return;
                }

                var windowScrollTop = $(window).scrollTop() + topBarHeight;
                var windowScrollBottom = $(window).scrollTop() + $(window).height();
                var parentwidth = $sidebar.parent().width();

                if (windowScrollTop <= sidebarTop) {
                    //Sidebar above the right content - release
                    $sidebar.removeClass('stick').removeClass('stick-bottom');
                } else if (windowScrollTop >= sidebarTop) {
                    //Sidebar top under the right content top
                    if (windowScrollBottom - sidebarRemainingSpace >= rightContentBottom) {
                        $sidebar.removeClass('stick');
                        if (!$sidebar.hasClass('stick-bottom')) {
                            $sidebar.addClass('stick-bottom');
                        }
                    } else {
                        $sidebar.removeClass('stick-bottom');
                        if (!$sidebar.hasClass('stick')) {
                            $sidebar.addClass('stick');
                        }
                    }

                    $sidebar.width(parentwidth);
                }
            }
        }

        $('.field-expand .expand-button').on('click', function(e) {
            e.preventDefault();
            $(this).toggleClass('open').siblings('div').slideToggle();
        });

        function updateTableSize() {
            $('.table-container').each(function () {
                if ($(this).parent().hasClass('panel-body')) return;
                var containerWidth = $(this).width();
                var tableWidth = $('table', this).width();

                if (tableWidth > containerWidth) {
                    $(this).addClass('scrollable');
                    $(this).mCustomScrollbar({
                        axis: "x",
                        mouseWheel: {
                            enable: true
                        }
                    });
                } else {
                    $(this).removeClass('scrollable');
                    $(this).mCustomScrollbar('destroy');
                }
            });
        }

    });
}(jQuery));
