(function () {
    'use strict';
    $(document).ready(function () {

        $(".play-video").modalVideo({
            channel: 'vimeo'
        });

        //Section Bg slider #1
        var swiperBG1 = new Swiper('.swiper-bg-1', {
            loop: true,
            spaceBetween: 30,
            slidesPerView: 2,
            autoplay: {
                delay: 5000,
            },
            breakpoints: {
                575: {
                    slidesPerView: 1,
                }
            },
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
            }
        });

        var swiperPresentations = new Swiper('.swiper-presentations', {
            pagination: {
                el: '.presentations .swiper-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">0' + (index + 1) + '</span>';
                },
            },
        });

        //Section Numbers #1

        $('.homepage .swiper-numbers').each(function (index) {
            var numbersSwiper = new Swiper($(this)[0], {
                pagination: {
                    el: $(this).find('.swiper-pagination'),
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '">0' + (index + 1) + '</span>';
                    },
                },
                observer: true,
                observeParents: true,
            });
        });

        //Section posts
        $('.section-posts .tabs-nav button').on('click', function() {
            $(this).parent().addClass('open');
            $(this).parent().siblings().removeClass('open');
            var sectionIndex = $(this).closest('.section-posts').index('.section-posts');
            var tabIndex = $(this).parent().index();
            console.log(sectionIndex);
            console.log(tabIndex);
            $('.section-posts').eq(sectionIndex).find('.tabs .tab').eq(tabIndex).siblings().hide();
            $('.section-posts').eq(sectionIndex).find('.tabs .tab').eq(tabIndex).fadeIn({
                start: function () {
                    $(this).css({
                        display: "flex"
                    })
                }
            });
        });

        //Section links
        var swiperLinks = new Swiper('.swiper-links', {
            loop: true,
        });

    });
}(jQuery));
