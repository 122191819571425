(function () {
    'use strict';
    $(document).ready(function () {

        $('.dropdown-select-btn').on('click', function() {
            $('.dropdown-select-btn').not(this).removeClass('open');
            $('.dropdown-select-btn').not(this).siblings('.dropdown-select-list').fadeOut();

            $(this).toggleClass('open');
            $(this).siblings('.dropdown-select-list').fadeToggle();
        });

        $('.dropdown-select-radio label').on('click', function() {
            var chosedValue = $(this).siblings('input').val();
            $(this).closest('.dropdown-select').find('.dropdown-select-btn').html(chosedValue);
        });

        $('.dropdown-select-checkbox input').on('change', function() {
            var chosedValues = '';

            $(this).closest('.dropdown-select').find('input').each(function(index) {
                if (this.checked) {
                    chosedValues = chosedValues+$(this).val()+', ';
                }
            });

            if (chosedValues == '') {
                chosedValues = $('input[name=choose-text]').val();
            }

            $(this).closest('.dropdown-select').find('.dropdown-select-btn').html(chosedValues);

        });

        //Form logic
        function sendElectromobilityForm() {

            var electromobilityName = $('input[name=cf-name]').val();
            var electromobilityPhone = $('input[name=cf-phone]').val();
            var electromobilityEmail = $('input[name=cf-email]').val();

            var electromobilityReporterFunction = $('input[name=cf-reporter-function]:checked').val();
            var electromobilityNetPartner = $('input[name=cf-net-partner]:checked').val();

            var electromobilityFacilityType = '';
            $('input[name=cf-facility-type]:checked').each(function() {
                electromobilityFacilityType = electromobilityFacilityType + $(this).val() + ', ';
            });

            var electromobilityParkingPlaces = $('input[name=cf-parking-places]:checked').val();
            var electromobilityContactHours = $('input[name=cf-contact-hours]:checked').val();

            var electromobilityStreet = $('input[name=cf-street]').val();
            var electromobilityStreetNr = $('input[name=cf-street-nr]').val();
            var electromobilityZIP = $('input[name=cf-zip]').val();
            var electromobilityLocality = $('input[name=cf-locality]').val();
            var electromobilityPlotNr = $('input[name=cf-plot-nr]').val();
            var electromobilityPrecinct = $('input[name=cf-precinct]').val();

            var electromobilityInfo = $('textarea[name=cf-info]').val();

            var electromobilityConsent = $('input[name=cf-consent]:checked').val() ? 'TAK' : 'NIE';

            var electromobilityDetails = {
                "electromobilityName": electromobilityName,
                "electromobilityPhone": electromobilityPhone,
                "electromobilityEmail": electromobilityEmail,
                "electromobilityReporterFunction": electromobilityReporterFunction,
                "electromobilityNetPartner": electromobilityNetPartner,
                "electromobilityFacilityType": electromobilityFacilityType,
                "electromobilityParkingPlaces": electromobilityParkingPlaces,
                "electromobilityContactHours": electromobilityContactHours,
                "electromobilityStreet": electromobilityStreet,
                "electromobilityStreetNr": electromobilityStreetNr,
                "electromobilityZIP": electromobilityZIP,
                "electromobilityLocality": electromobilityLocality,
                "electromobilityPlotNr": electromobilityPlotNr,
                "electromobilityPrecinct": electromobilityPrecinct,
                "electromobilityInfo": electromobilityInfo,
                "electromobilityConsent": electromobilityConsent,
            }

            var electromobilityDetailsJSON = JSON.stringify(electromobilityDetails);

            $.ajax({
                url: "/wp-admin/admin-ajax.php",
                method: "post",
                timeout: 10000,
                data: {
                    action: "send_electromobility_form",
                    electromobilityDetailsJSON: electromobilityDetailsJSON
                },
                success: function (data) {
                    if (data == '1') {
                        $('.ajax-success').slideDown();
                        $("#electromobility-form").trigger("reset");
                        $('.dropdown-select-btn').html($('input[name=choose-text]').val());
                    } else {
                        $('.ajax-error').slideDown();
                    }
                },
                error: function (data) {
                    $('.ajax-error').slideDown();
                },
                beforeSend: function () {
                    $('.ajax-success').slideUp();
                    $('.ajax-loading').slideDown();
                    $('.ajax-error').slideUp();
                },
                complete: function () {
                    $('.ajax-loading').slideUp();
                },
            });

        }

        function validatePhone(phone) {
            const re = /^[0-9+-\\(\\)\\ \\#]{1,12}$/;
            return re.test(String(phone));
        }

        function validateEmail(email) {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

        $('#cf-submit').on('click', function (e) {
            e.preventDefault();
            $('.cf-error').hide();

            var isAllValid = true;

            var fieldsToValidationText = ['#cf-name'];
            $(fieldsToValidationText).each(function(index, element) {
                if (!$(element).val()) {
                    $(this).siblings('.cf-error').slideDown();
                    isAllValid = false;
                }
            });

            if (validatePhone($('#cf-phone').val()) === false) {
                $('#cf-phone').siblings('.cf-error').slideDown();
                isAllValid = false;
            }

            if (validateEmail($('#cf-email').val()) === false) {
                $('#cf-email').siblings('.cf-error').slideDown();
                isAllValid = false;
            }

            // var fieldsToValidationSelect = ['input[name=cf-reporter-function]', 'input[name=cf-net-partner]', 'input[name=cf-net-partner]', 'input[name=cf-facility-type]', 'input[name=cf-parking-places]', 'input[name=cf-contact-hours]'];
            // $(fieldsToValidationSelect).each(function(index, element) {
            //     if (!$(element).is(':checked')) {
            //         $(this).closest('.dropdown-select').siblings('.cf-error').slideDown();
            //         isAllValid = false;
            //     }
            // });

            if (!$('#cf-consent').is(':checked')) {
                $('#cf-consent').siblings('.cf-error').slideDown();
                isAllValid = false;
            }

            if (!isAllValid) {
                return;
            }

            sendElectromobilityForm();
        });

    });
}(jQuery));
