(function () {
    'use strict';
    $(document).ready(function () {

        objectFitImages();

        // Smooth scroll to
        $('.scroll-animation').on('click', function (e) {
            e.preventDefault();

            const href = $(this).attr('href');
            if (href) {
                $("html, body").animate({
                    scrollTop: $(href).offset().top
                }, 1000);
            }
        });

        $('.scroll-animation a').on('click', function (e) {
            e.stopPropagation();
        });

        //Linki posiadające podmenu - wyłączenie domyślnego zachowania

       $("#site-menu li.menu-item-has-children > a").on('click', function (e) {
           e.preventDefault();
       });

       $("#site-menu li.menu-item-has-children > ul," +
           "#site-menu li.menu-item-has-children > ul > li").on('click', function (e) {
           e.stopPropagation();
       });

       //Mainmenu - level 1

        $("#site-menu li ul > li.menu-item-has-children").click(function () {
            $(this).toggleClass('open');
            $(this).children('ul').slideToggle();
            $(this).siblings('li').children('ul').slideUp();

        });

        //Menu toggle

        $(".menu-toggle").click(function(){
            $('.menu-overlay').fadeToggle({
                start: function () {
                    $(this).css({
                        display: "flex"
                    })
                }
            }).toggleClass('open');
            $(this).toggleClass("open");
            $('.header .container-site').toggleClass("open");
            $('html').toggleClass('no-scroll');

            var menuVideo = $('.main-menu-media video')[0];
            if (window.matchMedia("(min-width: 768px)").matches) {
                menuVideo.play();
            }
        });

        $(".menu-close").click(function(){
            $('.menu-overlay').fadeOut().removeClass('open');
            $('.menu-toggle').removeClass("open");
            $('.header .container-site').removeClass("open");
            $('html').removeClass('no-scroll');
            var menuVideo = $('.main-menu-media video')[0];
            menuVideo.pause();
        });

        $('#main-menu').mCustomScrollbar({
            axis: "y",
            mouseWheel: {
                enable: true
            }
        });

        //Search trigger
        $('.search-trigger').click(function () {
            $(this).toggleClass('active');
            $('.search-panel').fadeToggle('active');
            return false;
        });

        //Gateway

        $('.fhp-gateway .fhp-gateway-text').mCustomScrollbar({
            axis: "y",
            mouseWheel: {
                enable: true
            }
        });

        $('.fhp-gateway .fhp-gateway-close').on('click', function() {
            $('.fhp-gateway').fadeOut();
        });

    });
}(jQuery));
